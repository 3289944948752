import { faFacebook, faInstagram, faLinkedin, faTiktok, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Row, Col} from 'react-bootstrap';
export default function Footer() {
    return (
        <Row className="footer">
            <hr/>
            <Col className="text-center">
                <h5>Stay Connected With Us</h5>
                <a href="https://www.facebook.com/profile.php?id=61561947080324" target="_blank">
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a href="https://www.tiktok.com/@karvedup" target="_blank">
                    <FontAwesomeIcon icon={faTiktok} size="2x" />
                </a>
                <a href="https://x.com/karvedup_app" target="_blank">
                    <FontAwesomeIcon icon={faXTwitter} size="2x" />
                </a>
                <a href="https://www.instagram.com/karvedup" target="_blank">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a href="https://www.linkedin.com/company/105637148" target="_blank">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                </a>
                <a href="mailto:rick@karvedup.com" target="_blank">
                    <FontAwesomeIcon icon={faEnvelope} size="2x" />
                </a>
            </Col>
            <Col>
                <div>
                    <a href="/terms" target="_blank">Terms and Conditions</a>
                    <a href="/privacy" target="_blank">Privacy Policy</a>
                    <hr/>
                    <p>KarvedUp is not a replacement for your Medical Health Professional, any medical subjects related to your health should be consulted with them.</p>
                    <p>© 2025 KarvedUp LLC. All rights reserved.</p>
                </div>
            </Col>
        </Row>
    )
}