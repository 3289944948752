export default function Navbar() {
    return (
        <div className="karved-navbar">
            <div>
            <img src="/logo.png" alt="Karved Logo" className="logo" />
            </div>

            <a href="#signup" className="signup-link">
                <button className="signup"><h5>GET THE APP</h5></button>
            </a>
            
        </div>
    )
}