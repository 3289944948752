import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import api from './api';
import {useMemo, useState} from 'react';


function App() {
  const karvedApi = useMemo(() => new api(), [])
  const [userData, setUserData] = useState({
    email: '',
    firstName: '',
    receiveMarketing: false,
    termsAccepted: false
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitForm = async (evt) => {
    evt.preventDefault();
    if(!userData.email || !userData.firstName || !userData.termsAccepted) return;
    if(isSubmitting) return;
    setIsSubmitting(true);
    const response = await karvedApi.joinMailingList(userData);
    if (response){
      setSuccess(true);
    } else {
      setError(true);
      setIsSubmitting(false);
    }
  }

  return (
    <div className="App pt-1">
      <h1 className=" d-none d-md-block mt-5 text-center">
        HEALTH THROUGH HABITS
      </h1>
      {/* 
      <Container className="mb-5 d-none d-md-flex">
        <Row >
          <Col xs={12} md={4} className="bg-purple">
            <img src="/workouts.png" alt="Workouts" className="img-fluid" />
          </Col>

          <Col xs={12} md={4} className="bg-green">
            <img src="/mealplan.png" alt="Nutrition" className="img-fluid" />
          </Col>

          <Col xs={12} md={4} className="bg-purple">
            <img src="/supplements.png" alt="Sleep" className="img-fluid" />
          </Col>
        </Row>
      </Container>
      */}

      <Container id="app" className="mt-5">
        <Row>
         
        <div className="text-center">
          <div className="mx-auto" style={{maxWidth: '600px'}}>
            <img src="/yourcoaches.png" alt="App" className="img-fluid"/>
          </div>
        </div>
        <h1 className="text-center">
          Not just a Fitness Coach... <br/><br/> An Entire Coaching Staff!
        </h1>
      
        </Row>
        <Row className="mt-5">
          <Col xs={12} md={6} className="bg-green text-center">
            <div className="mx-auto" style={{maxWidth: '500px'}}>
              <img src="/trainer.png" alt="Trainer" className="img-fluid"  />
            </div>
            <h3 className="text-center">Olga</h3>
            <h5 className="text-center">Your Fitness Coach</h5>
          </Col>
          <Col xs={12} md={6} className="bg-purple text-center">
            <img src="/TrainerInfo.png" alt="Nutritionist" className="img-fluid"  />
          </Col>
        </Row>

        <Row className="mt-5 d-none d-sm-flex">
          <Col xs={12} md={6} className="bg-purple text-center">
            <img src="/ChefInfo.png" alt="Nutritionist" className="img-fluid" />
          </Col>
          <Col xs={12} md={6} className="bg-green text-center">
            <div className="mx-auto" style={{maxWidth: '500px'}}>
              <img src="/chef.png" alt="Nutritionist" className="img-fluid" />
            </div>
            <h3 className="text-center">Luigi</h3>
            <h5 className="text-center">Your Chef / Nutritionist</h5>
          </Col>
        </Row>

        <Row className="mt-5 d-flex d-sm-none">
          <Col xs={12} md={6} className="bg-green text-center">
            <div className="mx-auto" style={{maxWidth: '500px'}}>
              <img src="/chef.png" alt="Nutritionist" className="img-fluid" />
            </div>
            <h3 className="text-center">Luigi</h3>
            <h5 className="text-center">Your Chef / Nutritionist</h5>
          </Col>
          <Col xs={12} md={6} className="bg-purple text-center">
            <img src="/ChefInfo.png" alt="Nutritionist" className="img-fluid" />
          </Col>
          
        </Row>

        <Row className="mt-5">
          <Col xs={12} md={6} className="bg-green text-center">
            <div className="mx-auto" style={{maxWidth: '500px'}}>
              <img src="/doctor.png" alt="Doctor" className="img-fluid"  />
            </div>
            <h3 className="text-center">Ron</h3>
            <h5 className="text-center">Your Lab Assistant / Supplement Expert</h5>
          </Col>
          <Col xs={12} md={6} className="bg-purple">
            <img src="/LabAssistantInfo.png" alt="Nutritionist" className="img-fluid"  /> 
          </Col>
        </Row>
      </Container>
      
      <Container id="signup" >
          <Row>
            

            <Col xs={{span: 10, offset: 1}} className="sign-up-form" >
              {!success && (                
                  <Form onSubmit={submitForm}>
                  <h2 className="text-center ">
                    Get Notified When We Launch!
                  </h2>
                  {error && <p className="text-danger mt-3">An error occurred. Please try again.</p>}
                    <Form.Group>
                      <Form.Label><strong>Email</strong></Form.Label>
                      <Form.Control value={userData.email}
                                    type="email"
                                    onChange={evt => setUserData({...userData, email: evt.target.value})} 
                                    isValid={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userData.email)} 
                                    isInvalid={!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userData.email)}
                                    placeholder="Enter your email" />
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Label><strong>First Name</strong></Form.Label>
                      <Form.Control value={userData.firstName}
                                    onChange={evt => setUserData({...userData, firstName: evt.target.value})}
                                    isValid={userData.firstName.length > 0}
                                    isInvalid={userData.firstName.length <= 0}
                                    type="text" placeholder="Enter your first name" />
                    </Form.Group>

                    

                    <Form.Group className="mt-3">
                      <Form.Check type="checkbox" 
                                  label={
                                    <p>
                                      I am over 18 years of age and agree to the <a href="/terms" target="_blank">terms and conditions</a> and the <a href="/privacy" target="_blank">privacy policy</a>.
                                    </p>
                                  }
                                  checked={userData.termsAccepted}
                                  isInvalid={!userData.termsAccepted}
                                  isValid={userData.termsAccepted}
                                  onChange={evt => setUserData({...userData, termsAccepted: evt.target.checked})} />
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Check type="checkbox" 
                                  label="(optional) - I would like to receive promotional emails related to KarvedUp prior to and after the launch. " 
                                  checked={userData.receiveMarketing}
                                  onChange={evt => setUserData({...userData, receiveMarketing: evt.target.checked})} />
                    </Form.Group>

                    <div className="text-center">
                      <button className="sign-up-button mt-3" type="submit" disabled={isSubmitting}>
                        <h6>NOTIFY ME <br/><br/>WHEN YOU LAUNCH</h6>
                      </button>
                      <br/>
                      
                      {error && <p className="text-danger mt-3">An error occurred. Please try again.</p>}
                      <p className="mt-5">
                      *** KarvedUp is not a medical provider and does not provide medical advice, diagnosis, or treatment. The information provided on this website is for informational purposes only and is not intended as a substitute for professional medical advice, diagnosis, or treatment. Always seek professional medical advice from your physician or other qualified healthcare provider with any questions you may have regarding a medical condition.***
                      </p>
                    </div>
                  </Form>
              )}

              {success && (
                <div className="text-center">
                  <h2>Thank you for signing up!</h2>
                  <p>We will notify you when we launch.</p>
                </div>
              )}
            </Col>
          </Row>
      </Container>
    </div>
  );
}

export default App;
