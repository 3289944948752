import axios from 'axios';
axios.defaults.baseURL = 'https://api.karvedup.com/api';

export default class api {
    constructor(){
        this.axios = axios;
    }

    async joinMailingList(userData){
        try {
            const response = await this.axios.post('/mail-opt-in/opt-in', userData);
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}