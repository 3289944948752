import { useEffect, useState } from "react";

export default function CookieAgreement () {
    const [agreed, setAgreed] = useState(false);

    useEffect(() => {
        const cookieAgreement = localStorage.getItem('cookieAgreement');
        if (cookieAgreement) {
            setAgreed(true);
        }
    }, []);

    if (agreed) return null;

    return (
        <div className="cookie-agreement">
            <p>
                By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.
            </p>
            <button onClick={() => {
                localStorage.setItem('cookieAgreement', 'true');
                setAgreed(true);
            }}>
                Accept Cookies
            </button>
        </div>
    )
}