/*

Privacy Policy
Effective Date: September 7, 2024

At KarvedUp, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your information when you use our services.
1. Information We Collect
We collect the following types of information:

Personal Information: Information you provide when creating an account, such as your name, email address, and payment information.

Usage Data: Information about how you use the app, such as workout history, meal tracking, and interaction with notifications.

Device Data: Information about your device, including device type, operating system, and IP address.

2. How We Use Your Information
We use your information for the following purposes:

To provide and improve our services.

To communicate with you regarding your account, app updates, and promotions (with your consent).

To process payments for subscriptions or in-app purchases.

To analyze usage patterns and improve the app experience.

3. Sharing Your Information
We do not share your personal information with third parties, except:

When required by law or legal process.

With service providers that help us operate our business, such as payment processors.

With your consent, such as when you opt into offers or promotions.

4. Data Retention
We will retain your personal information for as long as necessary to provide you with the services you have requested, or as required by law.

5. Your Rights
Depending on your location, you may have the following rights regarding your personal data:

Access: You can request a copy of the personal information we hold about you.

Correction: You can request corrections to your personal information.

Deletion: You can request that we delete your personal information, subject to legal obligations.

6. Security
We take appropriate security measures to protect your personal data. However, no system is completely secure, and we cannot guarantee the absolute security of your data.

7. Cookies
We may use cookies and similar tracking technologies to collect usage data and improve the app experience. You can adjust your cookie preferences in your browser settings.

8. Children’s Privacy
Our services are not intended for users under the age of 18. We do not knowingly collect personal information from anyone under 18. If we learn that we have collected personal data from a minor, we will take steps to delete it.

9. Changes to this Policy
We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website or app.

10. Contact Us
If you have any questions about this Privacy Policy or your data rights, please contact us at Rick@KarvedUp.com
*/


export default function PrivacyPolicy() {
    //Conver the above text into markdown
    return (
        <div className="privacy-policy">
            <h1 className="mt-5 text-center">Privacy Policy</h1>
            <p>Effective Date: September 7, 2024</p>
            <p>At KarvedUp, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your information when you use our services.</p>
            <h2 className="mt-5">1. Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <ul>
                <li><strong>Personal Information:</strong> Information you provide when creating an account, such as your name, email address, and payment information.</li>
                <li><strong>Usage Data:</strong> Information about how you use the app, such as workout history, meal tracking, and interaction with notifications.</li>
                <li><strong>Device Data:</strong> Information about your device, including device type, operating system, and IP address.</li>
            </ul>
            <h2 className="mt-5">2. How We Use Your Information</h2>
            <p>We use your information for the following purposes:</p>
            <ul>
                <li>To provide and improve our services.</li>
                <li>To communicate with you regarding your account, app updates, and promotions (with your consent).</li>
                <li>To process payments for subscriptions or in-app purchases.</li>
                <li>To analyze usage patterns and improve the app experience.</li>
            </ul>
            <h2 className="mt-5">3. Sharing Your Information</h2>
            <p>We do not share your personal information with third parties, except:</p>
            <ul>
                <li>When required by law or legal process.</li>
                <li>With service providers that help us operate our business, such as payment processors.</li>
                <li>With your consent, such as when you opt into offers or promotions.</li>
            </ul>
            <h2 className="mt-5">4. Data Retention</h2>
            <p>We will retain your personal information for as long as necessary to provide you with the services you have requested, or as required by law.</p>

            <h2 className="mt-5">5. Your Rights</h2>
            <p>Depending on your location, you may have the following rights regarding your personal data:</p>
            <ul>
                <li><strong>Access:</strong> You can request a copy of the personal information we hold about you.</li>
                <li><strong>Correction:</strong> You can request corrections to your personal information.</li>
                <li><strong>Deletion:</strong> You can request that we delete your personal information, subject to legal obligations.</li>
            </ul>
            <h2 className="mt-5">6. Security</h2>
            <p>We take appropriate security measures to protect your personal data. However, no system is completely secure, and we cannot guarantee the absolute security of your data.</p>
            <h2 className="mt-5">7. Cookies</h2>
            <p>We may use cookies and similar tracking technologies to collect usage data and improve the app experience. You can adjust your cookie preferences in your browser settings.</p>
            <h2 className="mt-5">8. Children’s Privacy</h2>
            <p>Our services are not intended for users under the age of 18. We do not knowingly collect personal information from anyone under 18. If we learn that we have collected personal data from a minor, we will take steps to delete it.</p>
            <h2 className="mt-5">9. Changes to this Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website or app.</p>
            <h2 className="mt-5">10. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy or your data rights, please contact us at <a href="mailto:Rick@KarvedUp.com">Rick@KarvedUp.com</a></p>
        </div>
    );
}