import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.scss';
import { Container } from 'react-bootstrap';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import CookieAgreement from './shared/Cookie';
import Terms from './pages/terms';
import PrivacyPolicy from './pages/privacy';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Container className="karved-container">
    <Navbar/>
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<PrivacyPolicy/>}/>
    </Routes>
  </Router>
  <Footer/>
  <CookieAgreement/>
  </Container>
);


